import Card from "../card/card";
import NoPicture from '../../../assets/images/nopicture.jpg';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { fullPageLoader, updateAlertMessage, updateUser } from "../../../api-integration/commonSlice";
import { useLogoutMutation, useGenerateReferralIdMutation } from "../../../api-integration/secure/secure";
import { useEffect, useState } from "react";
import { FormatDate, clearCookies, getTranslatedText } from "../../../util/util";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Copy from '../../../assets/icons/copy.svg';
import TooltipComponent from '../../../components/common/bootstrap-component/tooltip-component';
import HelpModal from "../help-modal/help-modal";
import usePageTranslation from "../../../hooks/usePageTranslation";

interface IUserCardProps {
  loginUrl?: string; 
}
const UserCard= (props: IUserCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const PageTranslation = usePageTranslation();
  const generateReferralKeyModalId = "generateReferralKeyModal";
  const isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn') || 'false');
  const { user } = useSelector((state: any) => state.commonSlice);
  
 
  const [localRefKey, setLocalRefKey] = useState('');
  const [localStorageUser, setLocalStorageUser] = useState(JSON.parse(localStorage.getItem('user') as string));
  
  const dispatch = useDispatch();
  const [logoutAPI, { data: loggoutOut, isLoading, isSuccess, isError, error: logoutError }] =
    useLogoutMutation();
  const [generateRefKeyAPI, { data: refKeyData, isLoading:isKeyLoading, isSuccess: isKeySuccess, isError: isKeyError, error: KeyError }] =
    useGenerateReferralIdMutation();
    

  const logout = () => {
    dispatch(fullPageLoader(true));
    logoutAPI({});
  };

  const login = () => {
    sessionStorage.setItem('page', location?.pathname);
    if (props.loginUrl && props.loginUrl !== '') {
      navigate(props.loginUrl);
    } 
    else {
      navigate('/login');
    }
  }
  function generateReferralPath(refKey: string): string {
    const { protocol, host } = window.location;
    return `${protocol}//${host}/referral/${refKey}`;
  }
  useEffect(() => {
    const userRefKey = user?.referralId; 
    const referralPath = generateReferralPath(userRefKey);
    setLocalRefKey(referralPath || '');
    setLocalStorageUser(JSON.parse(localStorage.getItem('user') as string));
  }, [user]);

  const contentCopyURL = () => {
    navigator.clipboard.writeText(localRefKey || '')
    dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('copiedToClipboard.success.message', PageTranslation)}));
  }
  const handleGenerateReferralKey = () => {
    dispatch(fullPageLoader(true));
    generateRefKeyAPI({});
  }
  
  useEffect(() => {
    if(isKeySuccess){
      dispatch(fullPageLoader(false));
      dispatch(updateAlertMessage({ status: 'success', message: refKeyData.message }));
      if (refKeyData?.refId) {
        let user = JSON.parse(localStorage.getItem('user') as string);
        user.referralId = refKeyData?.refId;
        user.showRefKey = false;
        dispatch(updateUser(user));
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
  }, [isKeySuccess, isKeyError]);

  useEffect(() => {
    if (loggoutOut) {
      dispatch(fullPageLoader(false));
      localStorage.clear();
      dispatch(updateAlertMessage({ status: 'success', message: getTranslatedText('logout.success.message', PageTranslation)}));
      clearCookies('accessToken')
      localStorage.removeItem('user');
      navigate('/login');
    }
    if (logoutError) {
      dispatch(updateAlertMessage({ status: 'error', message: getTranslatedText('logout.failure.message', PageTranslation)}));
      dispatch(fullPageLoader(false));
    }
  }, [loggoutOut, logoutError]);
  return (
    <>
    <Card logo={true} titleType={1} share={true} settings={true} settingsClicked={() => navigate(`/app/userProfileEdit`)} 
      homeDisabled={location?.pathname.includes('home')} 
      settingsDisabled={location?.pathname.includes('userProfileEdit')} 
      help={true} Feedback={true} 
      logout={isLoggedIn ? true : false} 
      home={true} onLogout={logout} 
      title={getTranslatedText('myProfile.card.title', PageTranslation)} id="user_profile" 
      helpTitle={getTranslatedText('myProfile.cardHelp.title', PageTranslation)} 
      helpContent={getTranslatedText('myProfile.cardHelp.content', PageTranslation)} 
    >
      <div className="d-flex justify-content-between">
        <div>
          <h5 className="card-title">
            {isLoggedIn && user?.firstName && user?.lastName ?
                <>
                  <div className="cursor-pointer" onClick={() => navigate(`/app/userProfileEdit`)}>
                    {user?.firstName ? user?.firstName : ''}
                    <br />
                    {user?.lastName ? user?.lastName : ''}
                  </div>
                </>
                : getTranslatedText('guestWelcome.text', PageTranslation)
            }
          </h5>
        </div>
        <div>
          <img 
            src={isLoggedIn && user && user.picPath ? user?.picPath : NoPicture} 
            className="blc_image_lg cursor-pointer rounded rounded-circle img-fluid" 
            onClick={() => navigate(`/app/userProfileEdit`)} 
          />
        </div>
      </div>
      {
        (!isLoggedIn) && 
        <div className="d-inline-block">
          <TooltipComponent title={getTranslatedText('login.tooltip', PageTranslation)} >
            <button className="btn btn-primary btn-md rounded-pill px-3" onClick={login}>
              {getTranslatedText('login.text', PageTranslation)}
            </button>
          </TooltipComponent>
          </div>
      }
      {isLoggedIn && user?.usrCreatedDate &&
        <div className="card-text">
          {getTranslatedText('promptAuthor.text', PageTranslation)}<br />
          <h3>
            <NavLink to={`/app/accounting`} className="text-decoration-none">
              <TooltipComponent title={getTranslatedText('ccTotal.tooltip', PageTranslation)} > 
                {getTranslatedText('cc.text', PageTranslation)} {user?.totalCCPoints ? new Intl.NumberFormat('en-US').format(user?.totalCCPoints) : ''}
              </TooltipComponent>
            </NavLink>
          </h3>
          {user?.referralId ?
            <div>
                {getTranslatedText('refId.text', PageTranslation)}:<br />
              <div className="d-flex mb-2">
                <TooltipComponent title={getTranslatedText('refId.tooltip', PageTranslation)} > 
                  <h4>{user?.referralId}</h4>
                </TooltipComponent>
                <TooltipComponent title={getTranslatedText('refKeyCopy.tooltip', PageTranslation)} >
                  <img src={Copy} className='ps-2 h-1-5 cursor-pointer align-baseline' onClick={contentCopyURL} /><br />
                </TooltipComponent>
              </div>
            </div>
          : 
            <div className="mb-2">
              <TooltipComponent title={getTranslatedText('refKeyGenerate.tooltip', PageTranslation)} >
                <button type="button" 
                  className="btn btn-primary btn-md rounded-pill px-3" 
                  data-bs-toggle="modal" 
                  data-bs-target={`#${generateReferralKeyModalId}`}
                >
                  {getTranslatedText('refKeyGenerate.text', PageTranslation)}
                </button>
              </TooltipComponent>
            </div>
          }
          {getTranslatedText('bluecallomerSince.text', PageTranslation)} {new Date(user?.usrCreatedDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}<br />
        </div>
      }
    </Card>
    <HelpModal 
      title={getTranslatedText('refKeyHelp.modal.title', PageTranslation)} 
      content={getTranslatedText('refKeyHelp.modal.content', PageTranslation)}
      id={generateReferralKeyModalId}
      callToAction_btn_label= {getTranslatedText('done.text', PageTranslation)}
      callToAction_btn_tooltip= ""
      callToAction_btn_func= {handleGenerateReferralKey} 
    />
  </>
  )
}

export default UserCard;